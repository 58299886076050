import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faMobile, faClipboard, faLaptopCode, faCartPlus, faTools, faDesktopAlt } from '@fortawesome/free-solid-svg-icons';
import { faAccusoft } from '@fortawesome/free-brands-svg-icons';
import { slideInLeft, slideInRight, zoomIn } from 'react-animations';
import Analytic from '../../images/assets/analytics-svgrepo-com.svg';
import SiteMap from '../../images/assets/sitemap-svgrepo-com.svg';
import Coding from '../../images/assets/computer-coding-svgrepo-com.svg';
import WebData from '../../images/assets/computer-website-svgrepo-com.svg';
import WebStyle from '../../images/assets/computer-edit-svgrepo-com.svg';
import Rank from '../../images/assets/browser-seo-and-web-svgrepo-com.svg';
import LaptopsSVG from '../../images/assets/laptop-smart-phone-svgrepo-com.svg';
import { useInView } from 'react-intersection-observer';
import { useSpring } from 'react-spring';
import image from '../images/bg-2.png';
import { AnimationWrapper } from 'react-hover-animation';


import {
  Contained,
  StyledSectionServices,
  Wrapper,
} from '../layout/elements';


const leftAnimation = keyframes`${slideInLeft}`;
const rightAnimation = keyframes`${slideInRight}`;
const zoomInAnimations = keyframes`${zoomIn}`;
const SmallWrapper = styled.div`
  width: 90%;
  margin: 0 auto;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  color: var(--primary-light);
  font-size: 2rem;
`;

const SerivcesHeader = styled.h3`
font-size: 3rem;
margin-bottom: 5px;
color: var(--text-highlight);
`;

const ServiceText = styled.div`
  width: 90%;
  margin: 0 auto;
  padding-bottom: 5rem;
  line-height: 1.6;
  font-size: 2.2rem;
  text-align: left;
  @media ${props => props.theme.mediaQueries.medium} {
    width: 90%;
  }
  @media ${props => props.theme.mediaQueries.small} {
    font-size: 1.7rem;
    width: 95%;
  }
  @media ${props => props.theme.mediaQueries.smallest} {
    width: 100%;
  }
`;

const H2Services = styled.h2`
  margin: auto;
  text-align: center;
  align-items: center;
  min-height: 5rem;
  padding-top:10px ;
  font-size: 3.3rem;
`;

const ContentBox = styled.div`
  animation: 1s ${zoomInAnimations};
  display: flex;
  margin: 10px auto 0 auto;
  flex-wrap: wrap;
  gap: 2em;
  padding: 0.25rem;
  grid-area: content;
  @media ${props => props.theme.mediaQueries.medium} {
    width: 90%;
    flex-direction: column;
  }
  @media ${props => props.theme.mediaQueries.small} {
    font-size: 1.7rem;
    width: 95%;
    flex-direction: column;
  }
  @media ${props => props.theme.mediaQueries.smallest} {
    width: 100%;
    flex-direction: column;
  }

  @media (max-width: 350px) {
    flex-direction: column;
  }


`;

const StyledIconMain = styled(FontAwesomeIcon)`
  margin: 20px 0 5px 0;
  color: var(--text-highlight);
  font-size: 8.2rem;
  transition: color 0.2s ease-out;
  &:hover {
    color: var(--primary-light);
  }
`;


const LeftContent = styled.p`
  animation: 1s ${leftAnimation};
  box-sizing: border-box;
  flex: 1; 
  flex-basis: 40%;
  background: var(--theme-services);
 // background-image: url(${LaptopsSVG}); /* Use the Coding SVG image as background */
 // background-size: cover; /* Cover the entire container with the image */
 //  background-position: center; /* Center the image */
  padding: 20px;
  margin-bottom: 1rem;
  text-align: center;
  border-radius: 5px;
  box-shadow: var(--theme-boxShadow);
  box-gradient: var(--theme-backgroundGradient);
  &:hover > ${StyledIconMain}{
    color: var(--primary-light); 
  }

  &:hover >  ${SerivcesHeader}{
    color: var(--primary-light); 
  }
  transition: transform .2s; /* Animation */
 &:hover {
  transform: scale(1.05); 
}

`;

const RightContent = styled.p`
  animation: 1s ${rightAnimation};
  box-sizing: border-box;
  flex: 1;
  flex-basis: 40%;
  background: var(--theme-services);
  padding: 20px;
  margin-bottom: 1rem;
  text-align: center;
  border-radius: 5px;
  box-shadow: var(--theme-boxShadow);
  box-gradient: var(--theme-backgroundGradient);
   &:hover > ${StyledIconMain}{
    color: var(--primary-light); 
  }

  &:hover >  ${SerivcesHeader}{
    color: var(--primary-light); 
  }
  transition: transform .2s; /* Animation */
 &:hover {
  transform: scale(1.05); 
}
`;

const StyledTextMain = styled.p`
  margin: 20px 0 5px 0;
  color: var(--text-highlight);
  font-size: 1.8rem;
  font-weight: 400;
  text-align: left;
  line-height: 1.6;
  @media ${props => props.theme.mediaQueries.medium} {
    width: 90%;
    text-align: center;
  }
  @media ${props => props.theme.mediaQueries.small} {
    font-size: 1.8rem;
    text-align: center;
    width: 95%;
  }
  @media ${props => props.theme.mediaQueries.smallest} {
    width: 100%;
    text-align: center;
    
  }
`;

const StyledGridMain = styled.div`
  grid-column-gap: 24px;
  grid-row-gap: 40px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 70px;
`;

const StyledGrid = styled.div`

  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-auto-columns: 1fr;
  display: flex;
  margin: 16px auto; 
  @media ${props => props.theme.mediaQueries.medium} {
    width: 90%;
    flex-direction: column;
  }
  @media ${props => props.theme.mediaQueries.small} {
    font-size: 1.7rem;
    width: 95%;
    flex-direction: column;
  }
  @media ${props => props.theme.mediaQueries.smallest} {
    width: 100%;
    flex-direction: column;
  }

  @media (max-width: 350px) {
    flex-direction: column;
  }
`;

const StyledGridSecond = styled.div`

  flex: 1;
  flex-basis: 40%;
  grid-row-gap: 16px;
  background-color: var(--theme-servicesSecond);
  border-radius: 12px;
  flex-direction: column;
  align-items: flex-start;
  padding: 36px 32px;
  display: flex;
  border-bottom: 1px rgba(20, 23, 27, .1) solid;
  border-right: 1px rgba(20, 23, 27, .1) solid;
  transition: transform .2s; /* Animation */
    &:hover {
      transform: scale(0.9); 
    }
`;

const ServicesH3 = styled.h3`
  margin-top: 3rem;
  padding-top: 3rem;
  margin-bottom: 0rem;
  font-size: 28px;
  font-weight: 900;
  //line-height: 120%;
  text-align: center;
  text-shadow: 1px 1px 2px #558ABB;
`;

const WavesH4 = styled.h4`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 900;
  line-height: 120%;
  text-align: center;
  padding-left: 16px; ;
  align:: center;
  `;

const TextBlock = styled.p`
color: var(--text-highlight);
text-align: center;
font-weight: 500;
line-height: 24px;
font-size: 1.8rem;
`;


const ImageH = styled.div `
 width: 50px;
 height: 100%;
 float: left;
`;


const ServicesSecondMain = styled.div `
animation: 0,5s ${zoomInAnimations};
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  height: auto;
  background-color: var(--theme-servicesSecondMain);


`;

const TextBlockSecond = styled.p`
  color: var(--lightTheme);
  padding: 40px 0;
  max-width: 124rem;;
  margin: auto ;
 // color: #575757;
  text-align: center;
  font-weight: 500;
  line-height: 24px;
  font-size: 1.9rem;
`;



const Services = () => {
  const { ref, inView } = useInView({
    threshold: 1.0, // adjust the threshold to your needs
  });
  return (
    <StyledSectionServices fullHeight id="služby" >
      <Contained>
        <Wrapper>
          <SmallWrapper>
            <ServiceText>
              <H2Services>Služby</H2Services>
            </ServiceText>
            <ContentBox>
            
              <LeftContent style={inView ? { animation: `${leftAnimation} 1s` } : {}}>
                <StyledIconMain icon={faLaptopCode} />
                <SerivcesHeader>Tvorba stránek</SerivcesHeader>
                <StyledTextMain>
                  Nabízím široké spektrum služeb v oblasti webových stránek. Kromě tvorby moderních a responzivních webových stránek se specializuji také na jejich optimalizaci pro vyhledávače. Díky tomu je možné zajistit, že vaše webové stránky budou co nejvýše umístěny v internetových vyhledávačích, což zvýší vaši online viditelnost a zlepší vaše šance na získání nových zákazníků.
                </StyledTextMain>
               </LeftContent>
              <RightContent style={inView ? { animation: `${rightAnimation} 1s` } : {}}>
                <StyledIconMain icon={faClipboard} />
                <SerivcesHeader>Správa webu</SerivcesHeader>
                <StyledTextMain>
                Správa webových stránek je klíčovým prvkem úspěšného online podnikání. Navíc nabízím služby správy a údržby vašich webových stránek, abyste se nemuseli starat o technické záležitosti a mohli se plně soustředit na vaše podnikání. Mnoho majitelů firem podceňuje důležitost pravidelné údržby, což může vést k zastaralému obsahu nebo dokonce k bezpečnostním hrozbám.
<br />
Investice do správy webu je investicí do budoucnosti vašeho podnikání – nezapomínejte na to!
                </StyledTextMain>
              </RightContent>
            </ContentBox>
            <ContentBox>
              <LeftContent style={inView ? { animation: `${leftAnimation} 1s` } : {}}>
                <StyledIconMain icon={faMobile} />
                <SerivcesHeader>Responzivní design</SerivcesHeader>
                <StyledTextMain>
                S responzivním designem vašich webových stránek získáte klíč k moderní, atraktivní a přívětivé prezentaci online. V dnešní digitální době je nezbytné, aby vaše stránky byly dokonale přizpůsobeny všem zařízením. To znamená, že vaše podnikání bude dostupné pro širší okruh uživatelů bez ohledu na to, zda vaši stránku navštěvují z počítače, tabletu nebo telefonu.
                <br />
 Zajistěte si přístup k novým zákazníkům a zvyšte svou konkurenceschopnost díky responzivnímu designu.
                   </StyledTextMain>
                   
              </LeftContent>
              <RightContent style={inView ? { animation: `${rightAnimation} 1s` } : {}}>
                <StyledIconMain icon={faAccusoft} />
                <SerivcesHeader>Optimalizace stránek</SerivcesHeader>
                <StyledTextMain>
                Chcete být vidět ve výsledcích vyhledávání a přilákat více návštěvníků na váš web? Nabízím profesionální služby SEO a optimalizace webových stránek, které vám zajistí lepší pozice ve vyhledávačích, zvýší vaši online viditelnost a zlepší výkon webu. Moderní a responzivní webové stránky nestačí – potřebujete, aby je vaši zákazníci snadno našli. Optimalizací pro vyhledávače vám pomohu zvýšit šance na konverze a dlouhodobý růst vašeho podnikání.</StyledTextMain>
              </RightContent>
            </ContentBox>
            <ContentBox>
              <LeftContent><StyledIconMain icon={faTools} /><SerivcesHeader>Úprava stránek</SerivcesHeader><StyledTextMain>Máte již existující webové stránky? Vylepším je k dokonalosti!

Nabízím služby úprav a rozšíření vašich webových stránek tak, aby plně odpovídaly potřebám vašich zákazníků a podpořily růst vašeho podnikání. Ať už chcete přidat nové funkce, zlepšit design nebo optimalizovat výkon, pomohu vám vytvořit web, který bude ještě efektivnější a úspěšnější.</StyledTextMain></LeftContent>
              <RightContent><StyledIconMain icon={faCartPlus} /><SerivcesHeader>Grafický design</SerivcesHeader><StyledTextMain>Vytvářím vizuální identity, které zaujmou na první pohled.

Můj grafický design kombinuje kreativitu a preciznost, ať už se jedná o návrh loga, firemní materiály nebo reklamní bannery. Každý projekt je pro mě novou výzvou, kde propojuji estetiku s funkčností, abych zajistil, že váš vizuální styl bude nejen atraktivní, ale také účinně komunikovat vaši značku a oslovovat vaše cílové publikum.</StyledTextMain></RightContent>
            </ContentBox>
          </SmallWrapper>

          <StyledGridMain>
            
            <ServicesSecondMain>
              
            <ServicesH3>Jak vzniká nový web</ServicesH3>
              <TextBlockSecond>Za každým kvalitním webem stojí hodiny promyšlené práce a propojení dovedností z různých oblastí. Tvorba webu není jen o designu, ale o tom, jak spojit estetiku s funkčností, aby váš projekt vynikl a měl osobitý charakter. Vaše vize je tím, co web oživuje a dodává mu jedinečnost.
Jsem tady, abych vám pomohl proměnit vaše nápady ve funkční a vizuálně atraktivní web, který bude nejen odrážet vaše hodnoty, ale také podporovat váš úspěch.
              </TextBlockSecond>
              </ServicesSecondMain>
            <StyledGrid>
              <StyledGridSecond><WavesH4><ImageH><Analytic /></ImageH>Zadání a analýza požadavků</WavesH4><TextBlock>Prvním krokem v procesu tvorby webových stránek je pochopení vize zadavatele ohledně budoucího webu - jak má vypadat a co má umět.</TextBlock></StyledGridSecond>
              <StyledGridSecond><WavesH4><ImageH><WebData /></ImageH>&nbsp;Obsah a struktura webu</WavesH4><TextBlock>Vytvořím tzv. wireframe, strukturu webu, který bude obsahovat vše potřebobné a poslouží jako podklad pro grafiku.</TextBlock></StyledGridSecond>
              <StyledGridSecond><WavesH4><ImageH><SiteMap /></ImageH> &nbsp;Grafický návrh stránek </WavesH4><TextBlock>Z navrženého modelu zpracuji grafickou podobu webu, kde uvidíte svůj web doslova v jasnějších barvách.</TextBlock></StyledGridSecond>
              </StyledGrid>
              <StyledGrid>
              <StyledGridSecond><WavesH4><ImageH><WebStyle /></ImageH>&nbsp; Vývoj, kódodvaní webu</WavesH4><TextBlock>Konečná fáze tvorby, kdy výsledkem jsou plně funkční webové stránky, včetně responzivního zobrazení.</TextBlock></StyledGridSecond>
              <StyledGridSecond><WavesH4><ImageH><Rank /></ImageH>&nbsp;Testování a nasazení</WavesH4><TextBlock>Po důkladném otestování a finálním schválení. Nasadím vaše nové webové stránky do online světa. </TextBlock></StyledGridSecond>
              <StyledGridSecond><WavesH4><ImageH><Coding /></ImageH>&nbsp; Marketing, SEO a rozvoj</WavesH4><TextBlock>Po spuštění projektu nastavím měřící nástroje a rád vám pomohu i s marketingovou kampaňí. Samozřejmostí je i následný servis a technická správa.</TextBlock></StyledGridSecond>
            </StyledGrid>
          </StyledGridMain>
        </Wrapper>
      </Contained>
      <Link to="reference" spy={true} smooth={true}>
        <StyledIcon icon={faChevronDown} />
      </Link>
    </StyledSectionServices>

  );
};
export default Services;