import React from 'react';
import styled from 'styled-components';
//import { useStaticQuery, graphql } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faGithub,
    faFacebookF,
    faLinkedinIn,
    faInstagram,
    faYoutube,
} from '@fortawesome/free-brands-svg-icons';

const Email = styled.a`
  color: var(--text-highlight);
  font-size: 2rem;
  position: relative;
  font-weight: 600;
  text-decoration: none;
  margin-bottom: 4rem;
  transition: color 0.2s ease-out;

  @media ${props => props.theme.mediaQueries.medium} {
    font-size: 1.8rem;
  }

  @media ${props => props.theme.mediaQueries.small} {
    font-size: 1.7rem;
  }

  @media ${props => props.theme.mediaQueries.smallest} {
    font-size: 1.4rem;
  }
`;

const SocialWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
  //border-top:1px solid red ;

  @media ${props => props.theme.mediaQueries.medium} {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  @media ${props => props.theme.mediaQueries.small} {
    margin-bottom: 3rem;
    margin-top: 3rem;
  }
`;

const StyledLink = styled.a`
  text-decoration: none;
  border-radius: 50%;
  width: 4.5rem;
  height: 4.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--primary-light);
  margin: 0 1.5rem;
  transition: all 0.2s ease-out;

  &:hover {
    background-color: var(--primary);
    border-color: var(--primary);
  }

  @media ${props => props.theme.mediaQueries.medium} {
    width: 4rem;
    height: 4rem;
  }

  @media ${props => props.theme.mediaQueries.small} {
    width: 3.5rem;
    height: 3.5rem;
    margin: 0 1rem;
    border: 1px solid var(--primary-light);
  }

  @media ${props => props.theme.mediaQueries.smallest} {
    width: 3rem;
    height: 3rem;
    margin: 0 0.8rem;
  }
`;



const StyledIcon = styled(FontAwesomeIcon)`
  color: var(--text-highlight);
  font-size: 2.2rem;
  transition: color 0.2s ease-out;

  ${StyledLink}:hover & {
    color: var(--background);
  }

   @media ${props => props.theme.mediaQueries.medium} {
    font-size: 2rem;
  }

  @media ${props => props.theme.mediaQueries.small} {
    font-size: 1.7rem;
  }

  @media ${props => props.theme.mediaQueries.smaller} {
    font-size: 1.6rem;
  }
`;

const Social = () => {
    /*    const { site } = useStaticQuery(graphql`
        query {
          site {
            siteMetadata {
              social {
                twitter
                instagram
                linkedin
                youtube
                facebook
                github
                email
              }
                 <StyledLink
                    rel="noreferrer"
                    target="_blank"
                    aria-label="Linkedin"
                    href={`https://www.linkedin.com`}
                >
                    <StyledIcon icon={faLinkedinIn} />
                </StyledLink>
            }
          }
        }
      `);*/

    return (
        <>
            <SocialWrapper>

                <StyledLink
                    rel="noreferrer"
                    target="_blank"
                    title="LinkedIn"
                    aria-label="LinkedIn"
                    href={`https://www.linkedin.com/in/michal-bambušek-95a28486`}
                >
                    <StyledIcon icon={faLinkedinIn} />
                </StyledLink>


                <StyledLink
                    rel="noreferrer"
                    target="_blank"
                    aria-label="Facebook"
                    title="Facebook"
                    href={`https://facebook.com/MIBAsoftweb`}
                >
                    <StyledIcon icon={faFacebookF} />
                </StyledLink>

                <StyledLink
                    rel="noreferrer"
                    target="_blank"
                    aria-label="Instagram"
                    title="Instagram"
                    href={`https://www.instagram.com/mibasoft_webdesign/`}
                >
                    <StyledIcon icon={faInstagram} />
                </StyledLink>
            </SocialWrapper>
        </>
    );
};

export default Social;