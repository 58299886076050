import styled from 'styled-components';

const Input = styled.input`
    //border: 1px solid #E6343B; 
    padding: 1em; 
    width: 100%; 
    font-size: 2rem;
    border-radius: 5px;
`; 

export default Input;