import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    html {
      font-size: 62.5%; //1rem = 10px
      box-sizing: border-box;
      line-height: 1.15;
      @media ${props => props.theme.mediaQueries.largest} {
          font-size: 60%;
      }
      @media ${props => props.theme.mediaQueries.large} {
          font-size: 57.5%;
      }
      @media ${props => props.theme.mediaQueries.small} {
          font-size: 55%;
      }
    }
    * {
      outline: none;
      box-sizing: inherit;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
    }
    *,
    *::before,
    *::after {
      box-sizing: inherit;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    ::selection {
        background: var(--primary-lighter); /* WebKit/Blink Browsers */
      }
    body {
      //font-family: 'Galvji', 'Montserrat', 'sans-serif', "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji", '!default';
      font-family: 'Roboto', sans-serif;
      margin: 0;
      width: 100%;
      height: 100%;
      overflow: visible;
      --primary: ${props => props.theme.colors.main};
      --primaryToogle: ${props => props.theme.colors.mainToogle};
      --primary-light: ${props => props.theme.colors.light};
      --primary-lighter: ${props => props.theme.colors.lighter};
      --navbar: rgba(255, 255, 255, 0.95);
      --text: ${props => props.theme.colors.lightTheme.text};
      --text-highlight: ${props => props.theme.colors.lightTheme.textHighlight};
      --background: ${props => props.theme.colors.lightTheme.background};
      --white: #fff;
      --shadow-btn: rgba(7, 49, 69, .1);
      --shadow-color: rgba(0, 0, 0, 0.1);
      background-color: var(--background);
      &.light-mode {
      --navbar: rgba(255, 255, 255, 0.95);
      --text: ${props => props.theme.colors.lightTheme.text};
      --text-highlight: ${props => props.theme.colors.lightTheme.textHighlight};
      --background: ${props => props.theme.colors.lightTheme.background};
      --footer: rgba(213, 213, 216, 0.95);
      --theme-services: ${props => props.theme.colors.lightThemeServices.background};
      --theme-servicesContainer: ${props => props.theme.colors.lightThemeServicesContainer.background};
      --theme-servicesSecond: ${props => props.theme.colors.lightThemeServicesSecond.background};
      --theme-servicesSecondMain: ${props => props.theme.colors.lightThemeServicesSecondMain.background};
      --theme-tariff: ${props => props.theme.colors.lightThemeTariff.background};
      --theme-boxShadow: ${props => props.theme.colors.lightThemeServices.boxShadow};
      --theme-boxGradient: ${props => props.theme.colors.lightThemeServices.boxGradient};
      --theme-boxShadowPriceList: ${props => props.theme.colors.lightTheme.boxShadowPriceList};
      --theme-mainImage: ${props => props.theme.colors.lightThemeMainBackground.background};
      --theme-mainImageTwo: ${props => props.theme.colors.lightThemeMainBackgroundTwo.background};
      }
      &.dark-mode {
        --navbar: rgba(33, 33, 33, 0.95);
        --text: ${props => props.theme.colors.darkTheme.text};
        --text-highlight: ${props => props.theme.colors.darkTheme.textHighlight};
        --background: ${props => props.theme.colors.darkTheme.background};
        --footer: rgba(26, 28, 31, 0.86);
        --theme-services: ${props => props.theme.colors.darkThemeServices.background};
        --theme-servicesContainer: ${props => props.theme.colors.darkThemeServicesContainer.background};
        --theme-servicesSecond: ${props => props.theme.colors.darkThemeServicesSecond.background};
        --theme-servicesSecondMain: ${props => props.theme.colors.darkThemeServicesSecondMain.background};
        --theme-tariff: ${props => props.theme.colors.darkThemeTariff.background};
        --theme-boxShadow: ${props => props.theme.colors.darkThemeServices.boxShadow};
        --theme-boxShadowPriceList: rgba(230, 230, 230, 0.10) 0px 2px 4px 0px, rgba(230, 230, 230, 0.30) 0px 2px 16px 0px;
       // --theme-boxShadowPriceList:  ${props => props.theme.colors.darkTheme.boxShadowPriceList};
       //--theme-mainImage: brightness(50%);
       --theme-mainImage: ${props => props.theme.colors.darkThemeMainBackground.background};
       --theme-mainImageTwo: ${props => props.theme.colors.darkThemeMainBackgroundTwo.background};
      }
    }
    form,
    input,
    textarea,
    button,
    select,
    a {
      -webkit-tap-highlight-color: rgba(0,0,0,0);
    }
`;
