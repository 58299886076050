module.exports = {
  siteTitle: 'MIBA soft webdesign vývoj webových prezentací.', // Navigation and Site Title
  siteTitleAlt: 'MIBA soft webdesign - Profesionální webdesign a tvorba webových stránek : Vaše vize, moje řešení', // Alternative Site title for SEO
  siteTitleShort: 'mibasoft.cz', // short_name for manifest
  siteUrl: process.env.ROOT_URL || 'https://www.mibasoft.cz', // Domain of your site. No trailing slash!
  lang: 'cs', // Language Tag on <html> element
  pathPrefix: '/',
  siteLogo: 'images/miba_web01.png', // Used for SEO and manifest, path to your image you placed in the 'static' folder
  siteDescription: 'Vytvořím Vám rychle a levně profesionální webové stránky. Nejlepší poměr cena/výkon. Neváhejte se ozvat ještě dnes pro výhodnou nabídku.',
  keywords: 'mibasoft, mibasoft webdesign, tvorba webových stránek, levné webové stránky, levné webové stránky praha, praha levné webové stránky, cms, wordpress, webdesign, návrh webu, michal bambušek, grafika',
  author: 'Michal Bambušek', // Author for schemaORGJSONLD
  organization: 'MIBAsoft webdesign - Michal Bambušek',
  siteLocale: 'cs_CZ',
  siteType:'website',

  // Social component
  instagram: 'https://www.instagram.com',
  twitter: 'https://twitter.com',
  twitterHandle: '@mibasoft',
  github: 'https://github.com',
  linkedin: 'https://www.linkedin.com',
  youtube: 'https://youtube.com',
  email: 'info@mibasoft.cz',
};
