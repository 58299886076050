import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPhoneAlt,
    faAt,
    faUser,
    faMapMarkedAlt,
    faBriefcase
} from '@fortawesome/free-solid-svg-icons';
import config from '../../../../../config/website';

const WrapperCompanyInfo = styled.div`
    background: var(--primary-light);
    border-radius: 5px;
    margin-top: 9rem;
    margin-bottom: 8.67rem;
`;

const CompanyName = styled.h3`
  color: #fff;
  font-size: 2.5rem;
  margin: 0 0 1rem 0;
  text-align: center;
  white-space: nowrap;
  border-bottom-style: groove;
  @media (min-width: 700px) {
    text-align: center; 
  }
`;

const WrapperList = styled.ul`
    list-style: none;
    margin:0 0 2rem 0;
    padding:10px;
    text-align: center;
    font-size: 2.0rem;
    color: #fff; 
    @media (min-width: 700px) {
        text-align: left; 
    }
    white-space: nowrap;
`;

const StyledIconCompany = styled(FontAwesomeIcon)`
  color: var(--text-highlight);
  font-size: 2.1rem;
  color: #BCCEF8; 
  margin: 6px 20px 0px 0px;
  padding:1px 0 3px 0;
  @media ${props => props.theme.mediaQueries.medium} {
    font-size: 2rem;
  }
  @media ${props => props.theme.mediaQueries.small} {
    font-size: 1.7rem;
  }
  @media ${props => props.theme.mediaQueries.smaller} {
    font-size: 1.6rem;
  }
`;

const Email = styled.a`
  color: #fff;
  font-size: 2rem;
  position: relative;
  font-weight: 600;
  text-decoration: none;
  margin-bottom: 4rem;
  transition: color 0.2s ease-out;
  @media ${props => props.theme.mediaQueries.medium} {
    font-size: 1.9rem;
  }
  @media ${props => props.theme.mediaQueries.small} {
    font-size: 1.9rem;
  }
  @media ${props => props.theme.mediaQueries.smallest} {
    font-size: 1.9rem;
  }
`;

const CompanyInfo = () => (
    <WrapperCompanyInfo>
        <CompanyName>MIBA soft <br/>webdesign</CompanyName>
        <WrapperList>
            <li><StyledIconCompany icon={faUser} /> Michal Bambušek</li>
            <li><StyledIconCompany icon={faPhoneAlt} />+420 775 320 574</li>
            <li><StyledIconCompany icon={faAt} /><Email  
                href={`mailto:${config.email}`}
                title={`mailto:${config.email}`}
                target="_blank"
                rel="noreferrer"
                >
                 {config.email}
             </Email></li>
            <li><StyledIconCompany icon={faMapMarkedAlt} />Na Záhonech 845/20, Praha 4</li>
            <li><StyledIconCompany icon={faBriefcase} /> IČ: 76634191</li>
        </WrapperList>
    </WrapperCompanyInfo>
);

export default CompanyInfo