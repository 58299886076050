import React , { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';
import companyImage from '../images/webdesign.png';
import { useTransition, animated } from 'react-spring';
import useDarkMode from 'use-dark-mode';
import ScrollToTop from '../UI/scrollToTop';

const Logo = styled.div`
  text-decoration: none;
  font-family: inherit;
  background-image: url(${companyImage});
  background-repeat: no-repeat;
  min-height: 5.2rem;
  min-width: 26rem;
  text-align: center;
  vertical-align: middle;
  font-size: 10px;
`;

 const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  outline: none;
`;

const LogoTop = () => {
    return (
        <HeaderContainer>
            <Link to="úvod"><Logo></Logo></Link>
        </HeaderContainer>
    );
};

const StyledLink = styled(Link)`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const LogoNavBar = ({ notOnePageSection, setMenuOpened }) => {
  // State to show or hide scroll to top component, gets trigged based on the scroll link component
  const [showScrollTop, setShowScrollTop] = useState(false);
  // Animation
  const ScrollTopTransition = useTransition(showScrollTop, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

   // Logo transition based on dark or light mode
   const { value: darkMode } = useDarkMode(false);
   // Animation
   const LogoNavBarTransition = useTransition(darkMode, null, {
     config: { duration: 200 },
     from: { position: 'absolute', opacity: 0 },
     enter: { opacity: 1 },
     leave: { opacity: 0 },
   });

    // Render dark or light logo
    const renderLogo = () => {
      return LogoNavBarTransition.map(({ item, key, props }) =>
        item ? (
          < >
           
          </>
        ) : (
          <animated.div key={key} style={props}>
            <companyImage
              alt="Logo Dark"
              title="Logo Dark"
              //fixed={darkLogo.childImageSharp.fixed}
            />
          </animated.div>
        )
      );
    };
 


  return notOnePageSection ? (
    <></>
    ) : (
    <>
      <StyledLink
        to="úvod"
        smooth={true}
        spy={true}
        // When header section is active, hide scroll to top When inactive, show scroll to top
        onSetActive={() => setShowScrollTop(false)}
        onSetInactive={() => setShowScrollTop(true)}
        onClick={() => setMenuOpened(false)}
      >
      
      </StyledLink>
      {ScrollTopTransition.map(
        ({ item, key, props }) =>
          item && <ScrollToTop style={props} key={key} />
      )}
    </>
  );
};

export default LogoTop;