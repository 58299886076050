import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';

const StyledLink = styled(Link).attrs(() => ({
  activeClass: 'active',
}))`
  text-decoration: none;
  position: relative;
  font-family: inherit;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--text);
  cursor: pointer;
  padding: 1rem 2rem;
  font-size: 1.5rem;
  transition: color 0.2s ease-out;

  &:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  border-radius: 2px;
  background-color: var(--primary);
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}
&:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}
  &:hover {
    color: var(--primary);

  }
 
  &.active {
    color: var(--primary);
 
  }

    @media ${({ theme }) => theme.mediaQueries.small} {
    margin: 1rem 0;
    font-size: 1.6rem;
  }
`;

const NavItem = ({ link, clicked }) => (
  <StyledLink
    onClick={clicked}
    to={`${link
      .split(' ')
      .join('-')
      .toLowerCase()}`}
    spy={true}
    smooth={true}
    activeClass={clicked ? 'active' : ''}
   
  >
    {link}
  </StyledLink>
);

export default NavItem;
