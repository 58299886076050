import styled from 'styled-components';

const Textarea = styled.textarea`
    //border: 1px solid #E6343B;
    font-family: 'Roboto', sans-serif;
    padding: 1em; 
    width: 100%; 
    font-size: 2rem;
    border-radius: 5px;
`;

export default Textarea;