//import { fadeInRightBig } from "react-animations";

const theme = {
    colors: {
        //main: '#200b7d',
        main: '#3b92de',
        mainToogle: '#555',
        //light: '#089ECA',
        light: '#189be7',
        lighter: '#36A6BA',
        lightTheme: {
            text: '#575757',
            textHighlight: '#333',
            background: '#fff',
            boxShadowPriceList: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px',
        },
        darkTheme: {
            text: '#dadada',
            textHighlight: '#F0F0F0',
            background: '#212121',
            boxShadowPriceList: 'rgba(230, 230, 230, 0.12) 0px 2px 4px 0px, rgba(230, 230, 230, 0.32) 0px 2px 16px 0px',
        },
        lightThemeServices: {
            text: '#575757',
            textHighlight: '#333',
            boxShadow: '0 10px 29px 0 rgb(68 88 144 / 20%)',
            background: 'rgb(230,238,250)',
            background: 'linear-gradient(45deg, rgba(230,238,250,1) 27%, rgba(132,200,214,1) 100%)',
            //boxShadow: 'rgba(0,0,0,.5)',
        },
        darkThemeServices: { 
            text: '#dadada',
            textHighlight: '#F0F0F0',
            boxShadow: '0 10px 29px 0 rgb(102 255 255/ 20%)',
            background: 'rgb(71,89,112)',
            background: 'linear-gradient(45deg, rgba(71,89,112,1) 26%, rgba(34,54,59,1) 32%)',
            //boxShadow: 'rgba(255,255,255,.5)',
            //background: '#ababab',
        },
        lightThemeTariff: { 
            text: '#575757',
            textHighlight: '#333',
            background: '#e6eefa',
            boxShadow: 'rgba(0,0,0,.5)',
        },
        darkThemeTariff: {    
            text: '#dadada',
            textHighlight: '#F0F0F0',
            background: '#5691a8',
            boxShadow: 'rgba(74.74.73,.5)',
            //background: '#ababab',
        },
        lightThemeServicesContainer: {
            text: '#575757',
            textHighlight: '#333',
            background: '#f5f8fd',
            //boxShadow: 'rgba(0,0,0,.5)',
        },
        darkThemeServicesContainer: { 
            text: '#dadada',
            textHighlight: '#F0F0F0',
            background: '#4d4847',
        },
        lightThemeServicesSecond: {
            text: '#575757',
            textHighlight: '#333',
            background: '#f4f4f6',
            //boxShadow: 'rgba(0,0,0,.5)',
        },
        darkThemeServicesSecond: { 
            text: '#dadada',
            textHighlight: '#F0F0F0',
            background: '#777d9e',
        },
        lightThemeServicesSecondMain: {
            text: '#dadada',
            textHighlight: '#333',
            background: '#8FCCDA',
        },
        darkThemeServicesSecondMain: { 
            text: '#dadada',
            textHighlight: '#F0F0F0',
            background: '#00446C',
        },
        lightThemeMainBackground: { 
            background: 'linear-gradient(rgba(0, 0, 0, 0),rgba(0, 0, 0, 0))',
        },
        darkThemeMainBackground: { 
            background: 'linear-gradient(rgba(0, 0, 0, 0.53),rgba(0, 0, 0, 0.52))',
        },
        lightThemeMainBackgroundTwo: { 
            background: 'linear-gradient(rgba(0, 0, 0, 0.82),rgba(0, 0, 0, 0.83))',
        },
        darkThemeMainBackgroundTwo: { 
            background: 'linear-gradient(rgba(0, 0, 0, 0.53),rgba(0, 0, 0, 0.52))',
        },
    },
    mediaQueries: {
        smallest: `only screen and (max-width: 25em)`,
        smaller: 'only screen and (max-width: 31.25em)',
        small: 'only screen and (max-width: 37.5em)',
        medium: 'only screen and (max-width: 56.25em)',
        large: 'only screen and (max-width: 80em)',
        larger: 'only screen and (max-width: 90em)',
        largest: 'only screen and (max-width: 97em)',
    },

};

export default theme;