import React from 'react';
import styled from 'styled-components';
import useDarkMode from 'use-dark-mode';
import { useSpring, animated } from 'react-spring';

const Wrapper = styled(animated.div)`
  display: flex;
  transform: ${({ mobile }) => (mobile ? 'scale(1)' : 'scale(0.7)')};
  margin: ${({ mobile }) => (mobile ? '1rem 0' : '0')};
  margin-right: ${({ mobile }) => (mobile ? '0rem' : '0.5rem')};
  position: ${({ mobile }) => (mobile ? 'relative' : 'absolute')};
  right: ${({ mobile }) => (mobile ? null : '0')};
  /*border: 1px solid red;*/
  & input {
    position: absolute;
    right: -999rem;
    &:checked + .toggle {
      background-color: var(--primaryToogle);
      & .toggle__handler {
        background-color: #fff;
        transform: translate3d(42px, 0, 0) rotate(0);
        & .crater {
          opacity: 1;
        }
      }
    }
  }
`;

const Label = styled.label`
//pozadi posuvniku
  cursor: pointer;
  display: inline-block;
  position: relative;
  width: 70px;
  height: 26px;
  background-color: var(--primaryToogle);
  border-radius: 90px;
  transition: background-color 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
`;

const Span = styled.span`
//posuvnik
  display: inline-block;
  position: relative;
  z-index: 1;
  top: 3px;
  left: 3px;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  transition: all 400ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transform: rotate(-45deg);
`;

const DarkModeToggle = ({ mobile }) => {
  const darkMode = useDarkMode(false);
  //Animation
  const darkModeToggleSpring = useSpring({
    delay: 200,
    opacity: 1,
    from: { opacity: 0 },
  });
  return (
    <Wrapper style={darkModeToggleSpring} mobile={mobile}>
      <input
        type="checkbox"
        id="dn"
        checked={darkMode.value}
        onChange={darkMode.toggle}
      />
      <Label htmlFor="dn" className="toggle">
        <Span className="toggle__handler">
        </Span>
      </Label>
    </Wrapper>
  );
};

export default DarkModeToggle;