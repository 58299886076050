import styled from 'styled-components';
import img from '../images/bg-0.png';
import imgTwo from '../images/pngegg4.png';

export const Contained = styled.div`
  max-width: 124rem;
  z-index: 2;
  padding: 0 4rem;
  margin: 0 auto;
  width: 100%;
 
  @media ${props => props.theme.mediaQueries.small} {
    padding: 0 3rem;
  }

  @media ${props => props.theme.mediaQueries.smaller} {
    padding: 0 2rem;
  }
`;

export const StyledSection = styled.section`
  background-repeat: no-repeat ;
  background-position: center center ;
  background-image: var(--theme-mainImage) , url(${img});
  
  display: flex;
  position: relative;
  align-items: center;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: ${({ fullHeight }) =>
    fullHeight
      ? '100vh'
      : 'auto'}; 
  min-height: ${({ fullHeight }) =>
    fullHeight
      ? 'calc(var(--vh, 1vh) * 100)'
      : 'auto'}; 
         /* height: ${({ fullHeight }) =>
    fullHeight ? 'calc(var(--vh, 1vh) * 100)' : '100%'}; */
         /* min-height: ${({ fullHeight }) =>
    fullHeight ? '100vh' : '100%'}; */
`;

export const StyledSectionServices = styled.section`
  display: flex;
  background-color: var(--theme-servicesContainer);
  background-repeat: no-repeat ;
  background-position: left center ;
  background-image: var(--theme-mainImage) , url(${imgTwo});
  position: relative;
  align-items: center;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: ${({ fullHeight }) =>
    fullHeight
      ? '100vh'
      : 'auto'}; 
  min-height: ${({ fullHeight }) =>
    fullHeight
      ? 'calc(var(--vh, 1vh) * 100)'
      : 'auto'}; 
         /* height: ${({ fullHeight }) =>
    fullHeight ? 'calc(var(--vh, 1vh) * 100)' : '100%'}; */
         /* min-height: ${({ fullHeight }) =>
    fullHeight ? '100vh' : '100%'}; */
`;

export const Wrapper = styled.div`
  padding: 6rem 0rem;
  margin-top: 7rem;
  color: var(--text);
  display: flex;
  width: 100%; 
  align-items: center;
  flex-direction: column;
  transition: color 0.2s ease-out;

  @media ${({ theme }) => theme.mediaQueries.small} {
    margin-top: 6rem;
    padding: 4rem 0rem;
  }

  @media ${({ theme }) => theme.mediaQueries.smaller} {
    padding: 3rem 0rem;
  }
`;

