import React from 'react';
import styled from 'styled-components';
import Social from '../UI/social';
import Contact from '../contactForm/contactForm';
import GoogleMapReact from 'google-map-react';

import {
    Contained,
    StyledSection,
    Wrapper,
} from '../layout/elements';

const FooterDiv = styled.footer`
    position:fixed;
    bottom:0;
    width:100%;
    margin-top:20px ;
    align-items: center;
    text-align:center;
`;

const Link = styled.a`
    color: var(--text-highlight);
    font-size: 2rem;
    position: relative;
    font-weight: 600;
    text-decoration: none;
    margin-top: 3rem;
    padding-top: 10px ;
    transition: color 0.2s ease-out;
    @media ${props => props.theme.mediaQueries.medium} {
    font-size: 1.8rem;
    }
    @media ${props => props.theme.mediaQueries.small} {
    font-size: 1.7rem;
    }
    @media ${props => props.theme.mediaQueries.smallest} {
    font-size: 1.4rem;
    }
`;

const Map = styled.div`
    margin-bottom: 5rem;
    margin-top: 5rem;
    width: 100%;
`;

const CopyRight = styled.p`
  font-weight: 700;
  font-size: 1.3rem;
  color: var(--text-highlight);
  position: "fixed";
          left: 0;
          bottom: 0;
          right: 0;
  @media ${props => props.theme.mediaQueries.medium} {
   // margin-bottom: 0.5rem;
  }

  @media ${props => props.theme.mediaQueries.small} {
   // margin-bottom: 0.5rem;
  }
`;

const Footer = () => {
    return (
        <StyledSection fullHeight id="kontakt">
            <Contained>
                <Wrapper>
                    <Contact />
                    <Map><iframe width="100%" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" title="Mapa" src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=cs&amp;q=Na%20z%C3%A1honech%20845/20,%20Praha%204+(MiBa%20soft)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe></Map>
                    <Social />
                    <CopyRight>Copyright © {new Date().getFullYear()}, MIBA soft webdesign</CopyRight>
                    <FooterDiv> </FooterDiv>
                </Wrapper>
            </Contained>
        </StyledSection>
    );
};

export default Footer;