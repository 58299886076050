// import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
    background: var(--primary-light);
    display: block;
    margin-left: auto;
    margin-right: 0;
    border: 0; 
    color: #fff;
    margin-bottom:0px; 
    padding: 1em; 
    text-transform: uppercase; 
    width: 49%;
    border-radius: 5px;
    @media ${props => props.theme.mediaQueries.medium} {
    width: 100%;
    }
    @media ${props => props.theme.mediaQueries.small} {
        //font-size: 1.7rem;
        width: 100%;
    }
    @media ${props => props.theme.mediaQueries.smallest} {
        width: 100%;
    }    
    &:hover/*, &:focus */{
        background-color: #44C5F8/*var(--primary)*/;
        color: #fff; 
        outline: 0; 
        transition: background-color 0.3s ease-out; 
    }
    
`; 

export default StyledButton;