import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
//import SchemaOrg from './schema-org';
import config from '../../../../config/website';
import defaultMetaImage from '../../../../static/images/logo.png';

const SEO = ({
  siteMetadata: seo,
  postData = { childMarkdownRemark: {} },
  metaImage,
  frontmatter: postMeta = postData.childMarkdownRemark.frontmatter || {},
  title = postMeta.title || config.siteTitle,
  keywords = postMeta.keywords || config.keywords,
  description = postMeta.plainTextDescription ||
    postMeta.description ||
    seo.description,
  image = metaImage?.startsWith('http:') || metaImage?.startsWith('https:')
    ? metaImage
    : `${seo.canonicalUrl}${metaImage || defaultMetaImage}`,
  url = postMeta.slug
    ? `${seo.canonicalUrl}${postMeta.slug}`
    : seo.canonicalUrl,
}) => {
  return (
    <>
      <Helmet>
        {/* General tags */}
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="image" content={image} />
        <html lang="cs" amp />
        <meta charset="UTF-8" />

        {/* OpenGraph tags */}
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:keywords" content={keywords} />
        <meta name="ahrefs-site-verification" content="6e7425331fd34d05aa684f5e1d947596a59e90e7a1e28878a3f5b5ab4da2d68f"></meta>

        <script type="application/ld+json">
					{`    {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "mibasoft",
    "logo": "https://mibasoft.cz/images/logo.png'",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Na Záhonech 845/20",
      "addressLocality": "Praha 4 - Michle",
      "postalCode": "14100",
      "addressCountry": "CZ"
    },
    "url": "https://mibasoft.cz/",
    "telephone": "+420775320574",
    "email": "info@mibasoft.cz",
    "owns": "Michal Bambušek",
    "sameAs": [
      "https:/mibasoft.cz",
      "https://mibasoft.cz"
    ],
    "aggregateRating": {
      "@type": "AggregateRating",
      "bestRating": 5,
      "ratingValue": 5,
      "worstRating": 1,
      "reviewCount": 2
    }
  }`}
				</script>

 
      </Helmet>
     
    </>
  );
};

const SEOWithQuery = (props) => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
          canonicalUrl
          image
          author {
            name
          }
          organization {
            name
            url
            logo
          }
          social {
            twitter
          }
        }
      }
    }
  `);
  return <SEO siteMetadata={siteMetadata} {...props} />;
};

export default SEOWithQuery;
