import React, { useState, useEffect }from 'react';
import "@babel/polyfill";
import Layout from '../Components/layout/layout.js';
import Home from '../Components/sections/home';
import Services from '../Components/sections/services';
import Reference from '../Components/sections/reference';
import Footer from '../Components/sections/footer';
import Tariff from '../Components/sections/tariff.js';
import ScrollToTopNew from 'react-scroll-to-top';
import TopSVG from "../images/assets/chevron-up.svg";
import CookieBot from 'react-cookiebot';
//import MessengerCustomerChat from 'react-messenger-customer-chat';
import '../Components/styles/main.css';
import Test from '../Components/sections/test.js';
//import { BrowserRouter as Router, Routes, Route, Switch } from 'react-router-dom';
//import { Link } from 'react-scroll';
//import ReactGA from 'react-ga';
//const TRACKING_ID = "G-4RVCLWKBCS"; // OUR_TRACKING_ID
//<MessengerCustomerChat pageId="242676898918541" appId="1549171629252370"/>

function IndexPage() {
  const domainGroupId = 'ba1099cb-7584-4010-8f43-ce680b880431';
  return (
     <Layout>
      <CookieBot domainGroupId={domainGroupId} />
       <Home />
      <Services />
      <Reference />
      <Tariff />
   
      <Footer />
      
      <ScrollToTopNew smooth className="scroll-to-top-new" color="#fff" component={<TopSVG />}   />
    </Layout>
  );
}

export default IndexPage;