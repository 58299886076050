import React from 'react';
//import styled from 'styled-components';
import { css } from 'styled-components';

import Container from '../contactForm/components/Container';
//import Title from '../contactForm/components/Title';
import CompanyInfo from '../contactForm/views/Form/CompanyInfo';
import ContactForm from '../contactForm/views/Form/ContactForm';


const Contact = () => {
    return (
       <Container wrapper>
        <CompanyInfo />
        <ContactForm />
    </Container>

);
};

export default Contact;