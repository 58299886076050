import React, { useRef, useState  } from 'react';
import { useForm } from "react-hook-form";
import emailjs from '@emailjs/browser';
import styled, { css }  from 'styled-components';
import Form from '../../components/Form';
import StyledButton from '../../components/Button';
import Input from '../../components/Input';
import Textarea from '../../components/Textarea';
import Swal from 'sweetalert2';

const WrapperGrid = styled.div`
    ${props => props.full && css`
        grid-column: 1 / 3;
    `}
`;

const H2 = styled.h2`
    font-size: 3.3rem;
    margin-top:0px;
`;

export const ContactForm = () => {
    const [validated, setValidated] = useState(false);
    const { register, formState: { errors } } = useForm();


  const form = useRef();
  const sendEmail = (e) => {
    
    e.preventDefault();
    emailjs.sendForm('service_d2qxvvq', 'template_8p4ji1q', form.current, 'user_SqkoH1z3KVEGZIpdhYnZq')
    .then(() => {
          setValidated(false);
          e.target.reset();
    }, (error) => {
        console.log(error.text);
    });
    e.target.reset();
};

const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
       let timerInterval
Swal.fire({
  title: 'Vaše zpráva byla odeslána!',
  timer: 2000,
  timerProgressBar: true,
  icon: 'success',
  width: '50em',
  confirmButtonColor: '#44C5F8',
  didOpen: () => {
    timerInterval = setInterval(() => {
    Swal.getTimerLeft()
    }, 100)
  },
  willClose: () => {
    clearInterval(timerInterval)
  }
}).then((result) => {
  /* Read more about handling dismissals below */
  if (result.dismiss === Swal.DismissReason.timer) {
    console.log('I was closed by the timer')
  }
})
         sendEmail(event);
    }
    setValidated(true);
  };

const onSubmit = (data) => {
    sendEmail(data);
 }

  return (
    <div>
        <H2>Kontakt</H2>
        <Form ref={form} onSubmit={handleSubmit}>
        
            <WrapperGrid>
             
                <Input {...register("firstName", { required: true, maxLength: 20, minLength: 3 })} type="text" name="firstName" placeholder="Jméno" required/>
                {errors.firstName?.type === 'required' && "First name is required"}
            </WrapperGrid>
            <WrapperGrid>
                <Input {...register("lastName", { required: true, maxLength: 20 })} type="text" name="lastName" placeholder="Příjmení" required/>
            </WrapperGrid>
            <WrapperGrid>
                <Input type="email" name="email" placeholder="E-mail (povinné pole)" required/>
            </WrapperGrid>
            <WrapperGrid>
                <Input type="text" name="phone" placeholder="Telefon (povinné pole)" required/>
            </WrapperGrid>
            <WrapperGrid full>
                
                <Textarea name="message" rows="5" placeholder="S čím Vám mohu pomoct"></Textarea>
            </WrapperGrid>
            <WrapperGrid full>
            <StyledButton type="submit" value="Send" >Odeslat</StyledButton>
            </WrapperGrid>
        </Form>
    </div>
  );
};
 export default ContactForm;