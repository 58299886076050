import React from 'react';
import NavItems from './navItems/navItems';
import DarkModeToggle from './../UI/darkModeToggle';
//import Logo from '../UI/logoNavbar';

const DesktopMenu = ({ notOnePageSection }) => {
    return notOnePageSection ? null : (
        <>
            
            <NavItems />
            <DarkModeToggle />
        </>
    );
};

export default DesktopMenu;