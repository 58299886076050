import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';
import { useSpring, animated, config } from 'react-spring';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import homeBackground from '../images/hero-img.png';
import { Person } from "schema-dts";
import { helmetJsonLdProp } from "react-schemaorg";
import Helmet from 'react-helmet';

import {
  Contained,
  StyledSection,
  Wrapper,
} from '../layout/elements';

const SmallWrapper = styled.div`
  width: 95%;
  margin: 0 auto;
`;

const H1 = styled.h1`
//border: 1px solid red;
  font-weight: 180;
  font-size: 5.2rem;
  text-align: left;
 
  @media ${props => props.theme.mediaQueries.medium} {
    font-size: 5rem;
    font-weight: 160;
  }

  @media ${props => props.theme.mediaQueries.small} {
    font-size: 4.5rem;
    font-weight: 140;
  }

  @media ${props => props.theme.mediaQueries.smallest} {
    font-size: 4rem;
    font-weight: 120;
   
  }
`;

const Title = styled(animated.div)`
  line-height: 1.2;
  color: var(--text-highlight);
  transition: color 0.2s ease-out;
  text-align: justify nowrap;
  max-width: 50%;

  & span {
    font-weight: 600;
    color: var(--primary-light);
    transition: color 0.2s ease-out;
  }
`;

const Image = styled.img`
  width: 50%;
  margin: 0 auto;
  float: right;
  background-repeat: no-repeat;
  background-size: 40%;
  @media ${props => props.theme.mediaQueries.small} {
    position: absolute;
    right: -1px;
    bottom: 55%;
  }

  @media ${props => props.theme.mediaQueries.smallest} {
    position: absolute;
    bottom: 55%;
  }
`;

const SubTitle = styled(animated.div)`
  color: var(--text);
  font-weight: 400;
  margin-top:5rem;
  margin-bottom: 0rem;
  font-size: 2.2rem;
  transition: color 0.2s ease-out;
  text-align: justify;
  line-height: 1.6;

  @media ${props => props.theme.mediaQueries.medium} {
    width: 90%;
  }
  @media ${props => props.theme.mediaQueries.small} {
    font-size: 1.7rem;
    width: 95%;
  }
  @media ${props => props.theme.mediaQueries.smallest} {
    width: 100%;
  }
`;

const Home = () => {
  const TitleSpring = useSpring({
    config: config.wobbly,
    delay: 200,
    opacity: 1,
    transform: 'translateX(0px)',
    from: { opacity: 0, transform: 'translateX(40px)' },
  });

  const SubTitleSpring = useSpring({
    config: config.stiff,
    delay: 300,
    opacity: 1,
    transform: 'translateY(0px)',
    from: { opacity: 0, transform: 'translateY(40px)' },
  });

  const StyledIcon = styled(FontAwesomeIcon)`
  color: var(--primary-light);
  font-size: 2rem;
`;

  return (

    <StyledSection fullHeight id="úvod" >
          <Helmet
              script={[
                helmetJsonLdProp<Person>({
                  "@context": "https://schema.org",
                  "@type": "Person",
                  "name": "Michal Bambušek",
                  "telephone": "+420 775 320 574",
                  "email": "info@mibasoft.cz",
                  "address": {
                    "@type": "PostalAddress",
                    "streetAddress": "Na Záhonech 845/20,",
                    "addressLocality": "Praha 4"
                  }
                }),
              ]}
          
          >
<meta name="ahrefs-site-verification" content="6e7425331fd34d05aa684f5e1d947596a59e90e7a1e28878a3f5b5ab4da2d68f"></meta>
    <meta property="og:type" content="website" />
    <meta property="og:locale" content="cs_CZ" />
    <link rel="shortcut icon" href="https://mibasoft.cz/favicon.ico" type="image/x-icon" />
    <link rel="icon" href="https://mibasoft.cz/favicon.ico" type="image/x-icon" />



          </Helmet>
      <Contained>
        <Wrapper>
          <SmallWrapper>
          <Image src={homeBackground} alt='MIBA soft'></Image>
            <Title style={TitleSpring}>
            <H1>Nejste spokojeni se současným stavem vašeho webu? <br />Dejte svému podnikání šanci růst s novým webovým řešením.</H1> 
            </Title>
            <SubTitle style={SubTitleSpring}>
            Moderní, profesionálně navržený web již není výsadou jen velkých firem. I vy můžete mít webové stránky na míru, které nejen zaujmou, ale také podpoří růst vašeho podnikání.
<br /><br />
Mým cílem je dodávat zákazníkům cenově dostupné webové stránky, které reflektují jejich potřeby. Nejlevnější webová vizitka je již za 9 000 Kč. Nabízím kompletní servis – vytvoření stránek, jejich spuštění a pravidelnou údržbu, abyste měli jistotu, že váš web bude vždy fungovat na 100 %.
<br /><br />
Vše, co potřebuji od vás, je představa o funkcionalitě webu a název domény, kterou chcete registrovat. Postarám se o všechno ostatní, abyste měli plně funkční web bez starostí.
            </SubTitle>
            <Link to="reference" spy={true} smooth={true}>
            </Link>
          </SmallWrapper>
        </Wrapper>
      </Contained>
      <Link to="služby" spy={true} smooth={true}>
        <StyledIcon icon={faChevronDown} />
      </Link>
    </StyledSection>
  );
};

export default Home;