import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faCheck } from '@fortawesome/free-solid-svg-icons';
import { slideInLeft, slideInRight, zoomIn } from 'react-animations';
import {
  Contained,
  StyledSectionServices,
  Wrapper,
} from '../layout/elements';

const leftAnimation = keyframes`${slideInLeft}`;
const rightAnimation = keyframes`${slideInRight}`;
const zoomInAnimations = keyframes`${zoomIn}`;

const SmallWrapper = styled.div`
  width: 90%;
  margin: 0 auto;
`;

const PriceText = styled.div`
  width: 90%;
  margin: 0 auto;
  padding-top:3rem ;
  padding-bottom: 3rem;
  line-height: 30px;
  font-size: 3.7rem;
  text-align: center;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  color: var(--primary-light);
  font-size: 2rem;
`;

const Grid = styled.div`
  display: flex;
  margin: 0px auto 0 auto;
  flex-wrap: wrap;
  gap: 0.25rem;
  padding: 0.25rem;
  grid-area: content;
  justify-content: space-between;
  @media (max-width: 350px) {
    flex-direction: column;
  }
`;

const H2 = styled.h2`
  margin: auto;
  text-align: center;
  align-items: center;
  min-height: 5rem;
  padding-top:10px ;
  font-size: 3.7rem;
`;

const FirstCol = styled.div`
  color: var(--text-highlight);
  box-sizing: border-box;
  flex: 1; 
  flex-basis: 29%;
  padding: 20px;
  font-weight: 50;
  margin-bottom: 1rem;
  line-height: 0.5;
  font-size: 1.7rem;
  border-radius: 15px;
  justify-content: center;
  text-align: center;
   
  @media ${props => props.theme.mediaQueries.medium} {
    width: 90%;
  }
  @media ${props => props.theme.mediaQueries.small} {
    font-size: 1.7rem;
    width: 95%;
  }
  @media ${props => props.theme.mediaQueries.smallest} {
    width: 100%;
  }

`;

const CenikRow = styled.div`
  color: var(--text-highlight);
  line-height: 3rem;
  font-size: 18px;
`;

const StyledButton = styled.button`
    background-color: var(--primary-light);
    border: 0; 
    font-size: 1.4rem;
    color: #fff; 
    //margin-top:2em ;
    padding: 1em; 
    text-transform: uppercase; 
    width: auto;
    border-radius: 5px;
    align-items: center;
    
    &:hover/*, &:focus */{
        background-color: #44C5F8/*var(--primary)*/;
        color: #fff; 
        outline: 0; 
        transition: background-color 0.3s ease-out; 
    }
 `;

const StyledText = styled.span`
  color: var(--primary-light);
`;

const StyledGridMain = styled.div`
  grid-column-gap: 24px;
  grid-row-gap: 40px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 70px;
  align-items: center;
`;


const StyledGrid = styled.div`

  grid-row-gap: 16px;
  grid-column-gap: 12px;
  grid-template-rows: auto auto;
  grid-auto-columns: 1fr;
  display: flex;
  margin: 16px auto; 
  @media ${props => props.theme.mediaQueries.medium} {
    width: 90%;
    flex-direction: column;
  }
  @media ${props => props.theme.mediaQueries.small} {
    font-size: 1.7rem;
    width: 95%;
    flex-direction: column;
  }
  @media ${props => props.theme.mediaQueries.smallest} {
    width: 100%;
    flex-direction: column;
  }

  @media (max-width: 350px) {
    flex-direction: column;
  }
`;

const StyledGridPart= styled.div`
  animation: 1s ${zoomInAnimations};
  flex: 1;
  flex-basis: 40%;
  grid-row-gap: 16px;
  background-color: var(--theme-servicesSecond);
  border-radius: 12px;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px 10px 0 10px;
  display: flex;
  border-bottom: 1px rgba(20, 23, 27, .1) solid;
  border-right: 1px rgba(20, 23, 27, .1) solid;
  transition: transform .2s; /* Animation */
  transition: transform .2s; /* Animation */
 &:hover {
  
  transform: scale(1.05); 
}
`;

const TarifMain = styled.p`
  font-size: 20px;
  font-weight: 900;
  margin: 0.5rem auto;
  //margin: auto;
  text-align: center;
  //align-items: center;
  `;

  const Price = styled.p`
    font-size: 2.5rem;
  font-weight: 900;
  margin: 0.5rem auto;
  text-align: center;
  //align-items: center;
  color: var(--primary);
  border-bottom: 1px solid red;
  padding-bottom:20px;
   width:90%;
  `;

const List = styled.ul `
  margin: 0;
  padding: 0;
  list-style: none;
`;

const ListPart = styled.li `

  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  margin-bottom: 0.2rem;
  
  //font-size: 16px;
`;

const ListPartText = styled.p `
  margin-left:10px;
  font-size: 14px;
  margin-block-start: 0.5rem;
    margin-block-end: 0.5rem;
`;

const StyledIconList = styled(FontAwesomeIcon)`
  color: var(--primary-light);
  font-size: 2rem;
`;

const ListContact = styled.div `
 // margin-left:10px;
  //font-size: 14px;
  //margin-block-start: 0.5rem;
   // margin-bottom: 0.2rem !important;
    width: 100%;
    display: flex;
  align-items: center;
  justify-content: center;

    
`;

const StyledButtonPrice = styled.button`
    background-color: var(--primary-light);
    border: 0; 
    font-size: 1.4rem;
    color: #fff; 
    margin-bottom:1.5rem ;
    padding: 0.5em; 
    text-transform: uppercase; 
    min-width: 150px;
    width: 100%;
    border-radius: 5px;

     
    &:hover/*, &:focus */{
        background-color: #44C5F8/*var(--primary)*/;
        color: #fff; 
        outline: 0; 
        transition: background-color 0.3s ease-out; 
    }
 `;

const Tariff = () => {
  return (
    <StyledSectionServices fullHeight id="ceník">
      <Contained>
        <Wrapper>
          <SmallWrapper>
            
              <H2>Ceny vytvoření webových stránek</H2>
              <PriceText>Vaše nové webové stránky můžete mít již od 9 000 Kč
            </PriceText>
            <Grid>
              <FirstCol>
                <CenikRow>Ano, tvorba nového webu se může vejít i do takového <StyledText>rozpočtu.</StyledText></CenikRow>
                <CenikRow>Cena webových stránek i internetových obchodů je závislá na mnoha faktorech které ovlivňují celkovou cenu.</CenikRow>
                <CenikRow>Proto považuji za nejlepší kontaktovat mě s <StyledText>nezávaznou poptávkou</StyledText> a získat tak představu o ceně a časové náročnosti realizace Vašich stránek.</CenikRow>
              
              </FirstCol>

            </Grid>
            <StyledGridMain>
            <StyledGrid>
                <StyledGridPart>
                  <TarifMain>Vizitka</TarifMain>
                  <Price>od 9000 KČ</Price>
                  <List>
                      <ListPart><StyledIconList icon={faCheck} /><ListPartText>Bez redakčního systému</ListPartText></ListPart>
                      <ListPart><StyledIconList icon={faCheck} /><ListPartText>Vstupní analýza</ListPartText></ListPart>
                      <ListPart><StyledIconList icon={faCheck} /><ListPartText>Grafický návrh</ListPartText></ListPart>
                      <ListPart><StyledIconList icon={faCheck} /><ListPartText>Webdesign na míru</ListPartText></ListPart>
                      <ListPart><StyledIconList icon={faCheck} /><ListPartText>Landing page</ListPartText></ListPart>
                      <ListPart><StyledIconList icon={faCheck} /><ListPartText>Responzivní design</ListPartText></ListPart>
                      <ListPart><StyledIconList icon={faCheck} /><ListPartText>Doména, hosting</ListPartText></ListPart>
                      <ListPart><StyledIconList icon={faCheck} /><ListPartText>Základní SEO</ListPartText></ListPart>
                      <ListPart><StyledIconList icon={faCheck} /><ListPartText>SSL certifikát</ListPartText></ListPart>
                     
                  </List>
                  <ListContact>
                  <Link to="kontakt" spy={true} smooth={true}><StyledButtonPrice>Kontakt</StyledButtonPrice></Link>
                  </ListContact>
                </StyledGridPart>
                <StyledGridPart>
                  <TarifMain>Start</TarifMain>
                  <Price>od 11 000 KČ</Price>
                  <List>
                      <ListPart><StyledIconList icon={faCheck} /><ListPartText>Redakční systém WordPress</ListPartText></ListPart>
                      <ListPart><StyledIconList icon={faCheck} /><ListPartText>Webdesign na míru</ListPartText></ListPart>
                      <ListPart><StyledIconList icon={faCheck} /><ListPartText>Maximální počet stránek: 5</ListPartText></ListPart>
                      <ListPart><StyledIconList icon={faCheck} /><ListPartText>Responzivní design</ListPartText></ListPart>
                      <ListPart><StyledIconList icon={faCheck} /><ListPartText>Doména, hosting</ListPartText></ListPart>
                      <ListPart><StyledIconList icon={faCheck} /><ListPartText>SEO</ListPartText></ListPart>
                      <ListPart><StyledIconList icon={faCheck} /><ListPartText>Optimalizace rychlosti</ListPartText></ListPart>
                      <ListPart><StyledIconList icon={faCheck} /><ListPartText>SSL certifikát</ListPartText></ListPart>
                      <ListPart><StyledIconList icon={faCheck} /><ListPartText>Zabezpečení webu</ListPartText></ListPart>
                      
                    
                  </List>
                  <ListContact>
                  <Link to="kontakt" spy={true} smooth={true}><StyledButtonPrice>Kontakt</StyledButtonPrice></Link>
                  </ListContact>
                </StyledGridPart>
                <StyledGridPart>
                  <TarifMain>Komplet</TarifMain>
                  <Price>od 18 000 KČ</Price>
                  <List>
                      <ListPart><StyledIconList icon={faCheck} /><ListPartText>Redakční systém WordPress</ListPartText></ListPart>
                      <ListPart><StyledIconList icon={faCheck} /><ListPartText>Webdesign na míru</ListPartText></ListPart>
                      <ListPart><StyledIconList icon={faCheck} /><ListPartText>Maximální počet stránek: 10</ListPartText></ListPart>
                      <ListPart><StyledIconList icon={faCheck} /><ListPartText>Responzivní design</ListPartText></ListPart>
                      <ListPart><StyledIconList icon={faCheck} /><ListPartText>Doména, hosting</ListPartText></ListPart>
                      <ListPart><StyledIconList icon={faCheck} /><ListPartText>SEO</ListPartText></ListPart>
                      <ListPart><StyledIconList icon={faCheck} /><ListPartText>Optimalizace rychlosti</ListPartText></ListPart>
                      <ListPart><StyledIconList icon={faCheck} /><ListPartText>SSL certifikát</ListPartText></ListPart>
                      <ListPart><StyledIconList icon={faCheck} /><ListPartText>Zabezpečení webu</ListPartText></ListPart>
                  </List>
                  <ListContact>
                  <Link to="kontakt" spy={true} smooth={true}><StyledButtonPrice>Kontakt</StyledButtonPrice></Link>
                  </ListContact>
                </StyledGridPart>
                <StyledGridPart>
                  <TarifMain>Bussiness + E-Shop</TarifMain>
                  <Price>od 25 000 KČ</Price>
                  <List>
                      <ListPart><StyledIconList icon={faCheck} /><ListPartText>Redakční systém WordPress</ListPartText></ListPart>
                      <ListPart><StyledIconList icon={faCheck} /><ListPartText>Webdesign na míru</ListPartText></ListPart>
                      <ListPart><StyledIconList icon={faCheck} /><ListPartText>Neomezený počet stránek</ListPartText></ListPart>
                      <ListPart><StyledIconList icon={faCheck} /><ListPartText>Katalog produktů/zboží</ListPartText></ListPart>
                      <ListPart><StyledIconList icon={faCheck} /><ListPartText>Responzivní design</ListPartText></ListPart>
                      <ListPart><StyledIconList icon={faCheck} /><ListPartText>SEO</ListPartText></ListPart>
                      <ListPart><StyledIconList icon={faCheck} /><ListPartText>Optimalizace rychlosti</ListPartText></ListPart>
                      <ListPart><StyledIconList icon={faCheck} /><ListPartText>SSL certifikát</ListPartText></ListPart>
                      <ListPart><StyledIconList icon={faCheck} /><ListPartText>Zabezpečení webu</ListPartText></ListPart>
                  </List>
                  <ListContact>
                  <Link to="kontakt" spy={true} smooth={true}><StyledButtonPrice>Kontakt</StyledButtonPrice></Link>
                  </ListContact>
                </StyledGridPart>
       
              </StyledGrid>
              
              </StyledGridMain>
          </SmallWrapper>
        </Wrapper>
      </Contained>
      <Link to="kontakt" spy={true} smooth={true}>
        <StyledIcon icon={faChevronDown} />
      </Link>
    </StyledSectionServices>
  );
};

export default Tariff;