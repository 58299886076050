import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import theomedical from '../images/Theomedical_new_2.png';
import { LinkPreview } from '@dhaiwat10/react-link-preview';

import {
    Contained,
    StyledSection,
    Wrapper,
} from '../layout/elements';

const StyledIcon = styled(FontAwesomeIcon)`
  color: var(--primary-light);
  font-size: 2rem;
`;

const SmallWrapper = styled.div`
  width: 90%;
  margin: 0 auto;
`;

const PageThumbnail = () => {
  return <LinkPreview url='https://www.theomedical.cz/' width='400px' title="Theomedical s.r.o." />;
};

const Grid = styled.div`
  display: flex;
  margin: 0px auto 0 auto;
  flex-wrap: wrap;
  gap: 0.25rem;
  padding: 0.25rem;
  grid-area: content;
  justify-content: space-between;
  @media ${props => props.theme.mediaQueries.medium} {
    width: 90%;
    flex-direction: column;
  }
  @media ${props => props.theme.mediaQueries.small} {
    font-size: 1.7rem;
    width: 95%;
    flex-direction: column;
  }
  @media ${props => props.theme.mediaQueries.smallest} {
    width: 100%;
    flex-direction: column;
  }
  @media (max-width: 350px) {
    flex-direction: column;
  }
`;


const FirstCol = styled.div`
border:1 px solid red;
  color: var(--text-highlight);
  box-sizing: border-box;
  flex: 1; 
  flex-basis: 29%;
  padding: 20px;
  font-weight: 50;
  margin-bottom: 1rem;
  line-height: 1.2;
  font-size: 1.7rem;
  justify-content: center;
  text-align: center;
  transition: transform .5s ease;
   
  @media ${props => props.theme.mediaQueries.medium} {
    width: 90%;
  }
  @media ${props => props.theme.mediaQueries.small} {
    //font-size: 1.7rem;
    width: 95%;
  }
  @media ${props => props.theme.mediaQueries.smallest} {
    width: 100%;
  }
  &:hover {
    transform: scale(1.1);
   }
`;

const SecondCol = styled.div`
  color: var(--text-highlight);
  box-sizing: border-box;
  flex: 1; 
  flex-basis: 29%;
  padding: 20px;
  font-weight: 50;
  margin-bottom: 1rem;
  line-height: 1.2;
  font-size: 1.7rem;
  border-radius: 15px;
  justify-content: center;
  text-align: center;
  @media ${props => props.theme.mediaQueries.medium} {
    width: 90%;
  }
  @media ${props => props.theme.mediaQueries.small} {
    font-size: 1.7rem;
    width: 95%;
  }
  @media ${props => props.theme.mediaQueries.smallest} {
    width: 100%;
  }
`;

const ThirddCol = styled.div`
  box-sizing: border-box;
  flex: 1; 
  flex-basis: 29%;
  padding: 20px;
  font-weight: 50;
  margin-bottom: 1rem;
  line-height: 1.2;
  font-size: 1.7rem;
  border-radius: 15px;
  justify-content: center;
  text-align: center;
  
  @media ${props => props.theme.mediaQueries.medium} {
    width: 90%;
  }
  @media ${props => props.theme.mediaQueries.small} {
    font-size: 1.7rem;
    width: 95%;
  }
  @media ${props => props.theme.mediaQueries.smallest} {
    width: 100%;
  }
`;

const H2 = styled.h2`
  margin: auto;
  text-align: center;
  align-items: center;
  min-height: 5rem;
  padding-top:10px ;
  padding-bottom: 5rem;
  line-height: 30px;
  font-size: 3.3rem;
`;

const HoverImage = styled.div`
  width: 100%;
  margin: 0 auto;
  min-height:200px;
  background-repeat: no-repeat;
  background-size: 95%;
  border: 1px solid red;
  background-color: rgba(248, 247, 216, 0.7);
  
`;

const RefImage = styled.div`
  margin: 0 auto;
  padding: 0 0 0px 0;
  min-height:200px;
  background-image: url(${theomedical});
  background-repeat: no-repeat;
  background-size: 100%;
  border-radius: 15px;
  width: auto;
  display: block;
  max-width: 100%;
  @media ${props => props.theme.mediaQueries.medium} {
    width: 100%;
  }
  @media ${props => props.theme.mediaQueries.small} {
    //font-size: 1.7rem;
    width: 100%;
  }
  @media ${props => props.theme.mediaQueries.smallest} {
    width: 100%;
  }
  &:hover {
    transition: transform 400ms ease-in-out, -webkit-transform 400ms ease-in-out;
   }
`;

const RefLink = styled.a`
color: var(--text-highlight);
font-size: 2rem;
position: relative;
font-weight: 600;
text-decoration: none;
margin-top: 3rem;
padding-top: 10px ;
transition: color 0.2s ease-out;
@media ${props => props.theme.mediaQueries.medium} {
  font-size: 1.8rem;
}
@media ${props => props.theme.mediaQueries.small} {
  font-size: 1.7rem;
}
@media ${props => props.theme.mediaQueries.smallest} {
  font-size: 1.4rem;
}
`;

const Reference = () => {
    return (
        <StyledSection fullHeight id="reference">
            <Contained>
                <Wrapper>
                    <SmallWrapper>
                    <H2>Reference</H2>
                    <Grid>
              <FirstCol>

              <RefLink 
               href={`https://theomedical.cz/`}
               title="Theomedical s.r.o."
               target="_blank"
               rel="noreferrer"> 
                <RefImage href={`https://theomedical.cz/`}
               target="_blank"
               title="Theomedical s.r.o."
               rel="noreferrer"></RefImage>
            </RefLink>

             </FirstCol>
              <SecondCol></SecondCol>
              <ThirddCol>
              <PageThumbnail />
             </ThirddCol>
              </Grid>
                    </SmallWrapper>
                </Wrapper>
            </Contained>
            <Link to="ceník" spy={true} smooth={true}>
                <StyledIcon icon={faChevronDown} />
            </Link>
        </StyledSection>
    );
};

export default Reference;